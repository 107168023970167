define("discourse/plugins/chat/discourse/components/chat/form/row", ["exports", "@ember/routing", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _routing, _concatClass, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatFormRow = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @route}}
      <LinkTo
        @route={{@route}}
        @models={{@routeModels}}
        class={{concatClass "chat-form__row -link" (if @separator "-separator")}}
      >
        <div class="chat-form__row-content">
          {{@label}}
          {{icon "chevron-right" class="chat-form__row-icon"}}
        </div>
      </LinkTo>
    {{else}}
      <div class={{concatClass "chat-form__row" (if @separator "-separator")}}>
        <div class="chat-form__row-content">
          {{#if @label}}
            <span class="chat-form__row-label">{{@label}}</span>
          {{/if}}
  
          {{#if (has-block)}}
            <span class="chat-form__row-label">
              {{yield}}
            </span>
          {{/if}}
  
          {{#if (has-block "action")}}
            <div class="chat-form__row-action">{{yield to="action"}}</div>
          {{/if}}
        </div>
  
        {{#if (has-block "description")}}
          <div class="chat-form__row-description">
            {{yield to="description"}}
          </div>
        {{/if}}
      </div>
    {{/if}}
  
  */
  {
    "id": "VpY+UR6F",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[32,0],[[16,0,[28,[32,1],[\"chat-form__row -link\",[52,[30,2],\"-separator\"]],null]]],[[\"@route\",\"@models\"],[[30,1],[30,3]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"chat-form__row-content\"],[12],[1,\"\\n        \"],[1,[30,4]],[1,\"\\n        \"],[1,[28,[32,2],[\"chevron-right\"],[[\"class\"],[\"chat-form__row-icon\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[28,[32,1],[\"chat-form__row\",[52,[30,2],\"-separator\"]],null]],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-form__row-content\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"          \"],[10,1],[14,0,\"chat-form__row-label\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"          \"],[10,1],[14,0,\"chat-form__row-label\"],[12],[1,\"\\n            \"],[18,5,null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"          \"],[10,0],[14,0,\"chat-form__row-action\"],[12],[18,6,null],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\\n\"],[41,[48,[30,7]],[[[1,\"        \"],[10,0],[14,0,\"chat-form__row-description\"],[12],[1,\"\\n          \"],[18,7,null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]]],[\"@route\",\"@separator\",\"@routeModels\",\"@label\",\"&default\",\"&action\",\"&description\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/form/row.js",
    "scope": () => [_routing.LinkTo, _concatClass.default, _dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatFormRow;
});