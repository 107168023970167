define("discourse/plugins/chat/discourse/components/chat/message-creator/user", ["exports", "@glimmer/component", "@ember/service", "ember-modifier", "truth-helpers", "discourse/components/user-status-message", "discourse/helpers/user-status", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-user-avatar", "discourse/plugins/chat/discourse/components/chat-user-display-name", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _emberModifier, _truthHelpers, _userStatusMessage, _userStatus, _discourseI18n, _chatUserAvatar, _chatUserDisplayName, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatableUser extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    disabledUserLabel = (() => _discourseI18n.default.t("chat.new_message_modal.disabled_user"))();
    trackUserStatus = (() => (0, _emberModifier.modifier)((element1, _ref) => {
      let [user1] = _ref;
      user1.statusManager.trackStatus();
      return () => {
        user1.statusManager.stopTrackingStatus();
      };
    }))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="chat-message-creator__chatable -user"
          data-disabled={{not @item.enabled}}
        >
          <ChatUserAvatar @user={{@item.model}} @interactive={{false}} />
          <ChatUserDisplayName @user={{@item.model}} />
    
          {{#if (gt @item.tracking.unreadCount 0)}}
            <div class="unread-indicator -urgent"></div>
          {{/if}}
    
          {{userStatus @item.model currentUser=this.currentUser}}
    
          <div class="user-status" {{this.trackUserStatus @item.model}}>
            <UserStatusMessage
              @status={{@item.model.status}}
              @showDescription={{true}}
            />
          </div>
    
          {{#unless @item.enabled}}
            <span class="chat-message-creator__disabled-warning">
              {{this.disabledUserLabel}}
            </span>
          {{/unless}}
        </div>
      
    */
    {
      "id": "eakKNqBa",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__chatable -user\"],[15,\"data-disabled\",[28,[32,0],[[30,1,[\"enabled\"]]],null]],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@user\",\"@interactive\"],[[30,1,[\"model\"]],false]],null],[1,\"\\n      \"],[8,[32,2],null,[[\"@user\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\\n\"],[41,[28,[32,3],[[30,1,[\"tracking\",\"unreadCount\"]],0],null],[[[1,\"        \"],[10,0],[14,0,\"unread-indicator -urgent\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[1,[28,[32,4],[[30,1,[\"model\"]]],[[\"currentUser\"],[[30,0,[\"currentUser\"]]]]]],[1,\"\\n\\n      \"],[11,0],[24,0,\"user-status\"],[4,[30,0,[\"trackUserStatus\"]],[[30,1,[\"model\"]]],null],[12],[1,\"\\n        \"],[8,[32,5],null,[[\"@status\",\"@showDescription\"],[[30,1,[\"model\",\"status\"]],true]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[51,[30,1,[\"enabled\"]]],[[[1,\"        \"],[10,1],[14,0,\"chat-message-creator__disabled-warning\"],[12],[1,\"\\n          \"],[1,[30,0,[\"disabledUserLabel\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@item\"],false,[\"if\",\"unless\"]]",
      "moduleName": "/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/user.js",
      "scope": () => [_truthHelpers.not, _chatUserAvatar.default, _chatUserDisplayName.default, _truthHelpers.gt, _userStatus.default, _userStatusMessage.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatableUser;
});