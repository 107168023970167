define("discourse/plugins/chat/discourse/components/chat/composer/separator", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatComposerSeparator = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="chat-composer-separator"></div>
  
  */
  {
    "id": "ev2RhYTT",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"chat-composer-separator\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/composer/separator.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatComposerSeparator;
});